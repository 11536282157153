var helpers = {
// No helpers are included by default for the sake of size,
// But there's an example of a helper below
/*
  Date: function (args, content, blocks, options) {
    var today = new Date()
    var dd = today.getDate()
    var mm = today.getMonth() + 1 // January is 0!
    var yyyy = today.getFullYear()
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }
    today = mm + '/' + dd + '/' + yyyy
    return today
  } */
}

export default helpers
